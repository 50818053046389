import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function QuiltedImageList() {
  return (
    <ImageList
      sx={{ width: 300, height: 325, marginLeft: 1}}
      variant="quilted"
      cols={4}
      rowHeight={121}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
          <img
            {...srcset(item.img, 121, item.rows, item.cols)}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: './AdobeStock_109606559.jpg',
    title: 'Share',
    cols: 2,
  },
  {
    img: './AdobeStock_137821853.jpg',
    title: 'Committment',
    cols: 2,
  },
  {
    img: './AdobeStock_255448943.jpg',
    title: 'Honesty',
    cols: 2,
  },
  {
    img: './AdobeStock_135933591.jpg',
    title: 'brave',
    cols: 2,
  },
   {
    img: './AdobeStock_290109062.jpg',
    title: 'Puzzle',
    rows: 1,
    cols: 2,
  },
  {
    img: './AdobeStock_224007826.jpg',
    title: 'Smile',
    cols: 2,
  },
  {
    img: './AdobeStock_288508761.jpg',
    title: 'Team',
    cols: 2,
  },
  {
    img: './AdobeStock_224007577.jpg',
    title: 'Stars',
    cols: 2,
  },
  {
    img: './AdobeStock_152278654.jpg',
    title: 'Bridge',
    cols: 2,
  },
  {
    img: './AdobeStock_273890985.jpg',
    title: 'Trust',
    cols: 2,
  },
  {
    img: './AdobeStock_135933591.jpg',
    title: 'Team',
    cols: 2,
  },
  {
    img: './AdobeStock_128367440.jpg',
    title: 'Team',
    cols: 2,
  },
];
