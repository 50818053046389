import React from 'react';
import './App.css';
import Container from "@mui/material/Container";
import Home from "./components/Home";
import ListServices from './components/ListServices';
import ListAccordions from './components/ListAccordions';
import Footer from './components/Footer';
import DrawerAppBar from './components/Header';
import ReadinessMeter from './components/ReadinessMeter';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Blog from "./components/Blog";


function App() {
  return (
    <div className="App">
     
       <Container sx={{ width: 900 }}>
       {/* <Container maxWidth={false}> */}
        <DrawerAppBar />
        {/* <Link to="/blog">Blog</Link> */}
        {/* <Link to="/">Home</Link> */}
        <Home />
    <ListServices />
    <ListAccordions />
    <ReadinessMeter />
    <Footer />
    </Container>
     <Router>

   {/*  <Routes>
      <Route path="/blog" element={<Blog />} />
          <Route path="/" element={<Home />} />
        </Routes> */}
    </Router>
    </div>
  );
}

export default App;
