import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const ReadinessMeter = () => {
  return (
    <>
      <Typography>
        Review and Understand Where You Fall On the Technology Scale and can
        control, better prepared, and confidence around IT. Steps can be taken
        to improve your IT preparedness over time, no matter your current
        status. By taking the quiz you can gauge your readiness and make the
        necessary changes to stay on top.
      </Typography>

      <Typography align="center">
        <a href="mailto:demo@buildstrust.com">Contact us </a>
        Send an email at demo@buildstrust.com
      </Typography>
      <Typography align="center">
        WhatsApp <WhatsAppIcon color="primary" filled="true"/> +1(925) 315 9125
      </Typography>
    </>
  );
};

export default ReadinessMeter;
