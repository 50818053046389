import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles("dark", {
    backgroundColor: "rgba(255, 255, 255, .05)",
  }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function ListAccordions() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <>
      <Typography variant="paragraph" component="h3" marginTop={2}>
           We provide managed services
        </Typography>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography fontWeight="bold">Reporting & Analytics</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Most businesses realize that their reporting is outdated, but do
            very little to correct it. Reason? Perception that it’s too
            difficult, they have too little time; and it’s too complicated. But
            when you put reporting on the back burner, you put your business at
            risk. Leave the task of juggling data from multiple systems to us.
            By understanding your data requirement needs, we will design the
            best possible solution that is catered and customized for you. We
            are your Reporting & Analytics partners to help your data to improve
            accuracy, eliminate manual data gathering, increase collaboration;
            and finally interpret the data - Data that is meaningful, timely;
            and that tells a story. Data that enables you to take correct
            strategic decisions. Data that helps your organization understand
            current challenges so you can overcome them in order to gain desired
            results. Ready to take action? Reach out to our Reporting and
            Analytics experts to provide you the right solution.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography fontWeight="bold">Process Improvement</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Every process can be improved – the first step is to realize it
            needs improvement. Business Analysis is not just a research of
            identifying business gaps and needs, it’s not just determining
            solutions to current business issues; and most importantly, not just
            a software and systems development component. Business Analysis is
            Process Improvement. It’s a task that understands current challenges
            and introduces solutions to eliminate those challenges. It’s a
            strategic planning and policy development. Be it your planning and
            forecasting tools, or your budgeting calculators, or your master
            data management databases - don’t punish your system’s error or
            mistakes, instead work to prevent them from happening again. We are
            your strategic partners; bring us to the table to understand gaps on
            your existing processes so we can bring a different point of view on
            how to manage them better. If you’ve reached that first step, i.e.,
            realized your process needs improvement - Reach out to our Process
            Improvement experts to provide you the right solution.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography fontWeight="bold">
            Staffing: Technical, Finance, Business Development
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Do you have the right skills to help you reach the top? Is your
            skill set up to date? We all can be ignorant and believe the answer
            is Yes. But in order to be a Market Leader, we need to continuously
            be on our toes to ensure our skill set and resources are skilled
            with the latest technologies. You don’t have to invest in education
            by teaching your staff, you focus on doing what you do best – run
            your business. Let us handle finding and delivering the right
            technical resources (developers, architects, testers), financial
            resources (product managers, project managers, process managers),
            and business developers (sales force, marketing leaders) that will
            help you reach your destination. We all need staffing help – whether
            we agree or not. Reach out to our Staffing experts to provide you
            the right resources.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography fontWeight="bold">Application Development and Implementation</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Be it Mobile, Web or Desktop – we have experts with combined
            hundreds of thousands of hours experience in building, and enhancing
            applications. We are your IT partners that can guide you to the
            right direction, from choosing the correct tools, to building a
            comprehensive app, at a fraction of cost, without compromising on
            quality – PERIOD. Be it your B2B and B2C Products, Digital
            Catalogues, eCommerce Sites and Apps, Interactive Social Portals,
            Remote eLearning and Educational Platforms, Disaster Recovery Tools,
            Custom Financial Systems, Time and Expense Portals, or
            Infrastructure Architecture and Design. Be it custom application
            development, or revamping your existing legacy applications, if you
            are ready to make that change - Reach out to our Application
            Development experts to provide you the right solution.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <Typography fontWeight="bold">Automation</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
             We identify the use cases for Automation, that can save you recurring cost, remove effort on the recurring 
             processes that your team has to perform on a daily, weekly or Monthly bases. 
             This automation also helps in reducing manual errors, if any.
          </Typography>
        </AccordionDetails>
      </Accordion>
      </>
    </div>
  );
}
