import React from "react";
import QuiltedImageList from "../components/QuiltedImageList";
import { Box, Typography } from "@mui/material";
// import Card from "./Card";

const Home = () => {
  return (
    <>
      <Typography variant="h3" component="h2" marginTop={2} align="center">
        BuildsTrust - An IT Company
      </Typography>

      <Typography variant="h5" component="h5" marginTop={1} align="center">
        California, USA
      </Typography>

      <Box marginTop={2} sx={{ display: "flex" }}>
        <img src="./AdobeStock_288069459.jpg" alt="" height={325} />
        <QuiltedImageList />
      </Box>
      <Box>
        <Typography variant="h6" component="h4" marginTop={3}>
          {/* Who? */}
        </Typography>
        <Typography
          variant="paragraph"
          component="p"
          marginTop={1}
          alignContent="justified"
        >
          <strong>BUILDSTRUST: A Digital technology </strong> solutions
          providers from staffing, to Reporting and Analytics, to Product
          Development including Web, Mobile and Desktop tools, to Implenting
          Solutions for on-premises and multiple cloud vendors such as Microsoft
          Azure, Amazon Web Services, Oracle Cloud etc. <br />
        </Typography>
      </Box>
    </>
  );
};

export default Home;
