import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

export default function ListServices() {
  return (
    <>
     <Typography variant="paragraph" component="h3" marginTop={2}>
     We provide services catered to your specific needs:
        </Typography>
    <List sx={{ width: '100%', maxWidth: 1000, bgcolor: 'background.paper'}}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary="Trusted Business Advisor"
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                sx={{ color: 'text.primary', display: 'inline' }}
              >
                We don't just sell
              </Typography>
              {" — We are not about selling and making pitches. We take interest in you, understand your challenges, ask the right questions and offer you the right solution. Most importantly, we listen."}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary="Innovate!"
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                sx={{ color: 'text.primary', display: 'inline' }}
              >
                With Passion and Love
              </Typography>
              {" — We NEVER stop innovating, whether it’s Incremental: Increasing customer value while utilizing existing processes Disruptive: Applying new processes over existing processes Architectural: Learning lessons from existing successful processes to different processes Radical: Giving birth to new solutions."}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary="Deliver Results"
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                sx={{ color: 'text.primary', display: 'inline' }}
              >
                We Commit and Deliver
              </Typography>
              {' — Deliver highest quality, tested and proven, deployed in a timely fashion, within the budget.'}
            </React.Fragment>
          }
        />
      </ListItem>
    </List>
    </>
  );
}
